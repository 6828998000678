@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow: hidden;
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

.root {
  margin: 0 !important;
}

* {
  box-sizing: border-box;
}

.containers {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* Align items to the start of the container (left side) */
  height: auto;
  margin: 0 !important;
  background-color: #222831;
}

.image-carousel {
  display: flex;
  max-width: 40%;
}

.order-grid {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.order-container {
  margin: 0.5rem;
  display: grid;
  color: #ffd369;
  background-color: #222831;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 0.5rem;
  border-radius: 5px;
}

.order-actions {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.order-action {
  width: 70%;
  margin: 0.3rem;
}

.order-send-location,
.order-id {
  display: flex;
  font-size: small;
  justify-content: center;
  align-items: center;
}

.order-status {
  display: flex;
  font-size: small;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.order-send-location {
  max-width: 7rem;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex: 1;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #393e46;
  height: 100vh;
}

.action-button_title {
  font-size: 0.9rem;
}

.header {
  font-size: 1.5rem;
  background-color: #222831;
  padding: 0.7rem;
  padding-left: 6px;
  display: flex;
  align-items: center;
  color: black;
  height: 10vh;
  min-height: 12vh;
  justify-content: space-between;
  max-width: 100vw;
}

.app-contents {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 90vh;
  /* padding: 1rem; */
  overflow-y: scroll;
}

.navbar-logo {
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}

#root {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.payment-form {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100% !important;
  width: 100% !important;
}

.link {
  color: #ffd369;
  display: flex;
  justify-content: space-between;
  padding: 9px;
  width: 75%;
  background-color: #222831;
  border: 1px solid #ffd369;
  text-decoration: none;
  align-items: center;
}

/* .extendable{
  color: "#FFD369" !important
} */

.extendable:hover {
  background-color: #393e46;
  transition:
    border-radius 0.1s,
    background-color 0.1s;
}

.link:hover {
  background-color: #393e46;
  transition:
    border-radius 0.1s,
    background-color 0.1s;
}

.navbar-links {
  display: flex;
  flex-direction: column;
  padding: "10px";
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: none;
  font-size: 1rem;
  flex: 1;
  background-color: #f9f9f9;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 8px 10px;
  text-decoration: none;
  display: block;
  user-select: none;
  /* Disable text selection */
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropbtn {
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.discounted-price {
  color: #ffd369;
  margin-right: 0.5rem;
}

.not-found {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.center-title {
  color: #ffd369;
  font-size: 3rem;
}

.error-message {
  color: red;
  margin-bottom: 0;
}

.links {
  display: flex;
}

.subtext {
  color: #ffd369;
  font-size: 1.5rem;
  margin-top: 1rem;
}

.original-price {
  text-decoration: line-through;
  color: white;
  margin-right: 0.5rem;
}

.original-price-banner {
  text-decoration: line-through;
  color: white;
  margin-right: 0.5rem;
}

.in-stock {
  color: green;
  margin-left: 0.2rem;
}

.out-of-stock {
  color: red;
  margin-left: 0.2rem;
}

.dropdown-button {
  color: #ffd369;
  display: flex;
  justify-content: center;
  padding: 0.65rem;
  text-decoration: none;

  cursor: pointer;
  user-select: none;
  /* Disable text selection */
}

.dropdown-button-active {
  display: flex;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  /* Disable text selection */
}

.dropdown-button:hover {
  background-color: #393e46;
  width: 3.5rem !important;
  transition:
    border-radius 0.1s,
    background-color 0.1s;
}

.dropdown-button-active:hover {
  width: 3.5rem !important;
  transition:
    border-radius 0.1s,
    background-color 0.1s;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#266beb 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#266beb);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: l13 1.75s infinite linear;
}
@keyframes l13{ 
  100%{transform: rotate(1turn)}
}


@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loadingContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.item-list {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 0.5rem;
  /* justify-items: center !important; */

  /* padding: 1rem; */
}

.item-banner {
  display: flex;
  /* border: 0.3px solid #FFD369; */
  overflow: hidden;
  border-radius: 10px;
  margin: 0.5rem;
  /* background-color: #FFD369; */
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.025);

  padding: 1px;
}

.item-banner-image {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.welcome-text {
  animation: welcome-slide 4s ease forwards; /* Adjusted to 4s for total duration */
}

/* Keyframe for welcome-slide */
@keyframes welcome-slide {
  0% {
    transform: translateY(-100%); /* Start from the top */
    opacity: 0;
  }
  25% {
    transform: translateY(0); /* Slide to the middle */
    opacity: 1;
  }
  50% {
    transform: translateY(0); /* Stay in the middle */
    opacity: 1;
  }
  100% {
    transform: translateY(100%); /* Slide to the bottom */
    opacity: 0;
    display: none;
  }
}

.zaza-text {
  opacity: 0;
  transform: translateY(-100%);
  transition: transform 1s ease, opacity 1s ease;
}

.zaza-text.slide-in {
  opacity: 1;
  transform: translateY(0);
}


.twaspect{
  aspect-ratio: 3/2;
}

.onaspect{
  aspect-ratio: 1/1;
}

.item-banner-inner {
  padding: 0.8rem;
  display: flex;
  background-color: #222831;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 1rem;
  padding-right: 1rem;
}

.item-banner-inner:hover {
  /* background-color: #2c3137; */
  transition:
    border-radius 0.1s,
    background-color 0.1s;
}

.item-banner-title {
  font-size: 1rem;
  padding-top: 5px;
  font-weight: bold;
  color: #ffd369;
  cursor: pointer;
}

.item-banner-desc {
  font-size: 1rem;
  color: #555;
  margin-bottom: 8px;
  cursor: pointer;
}

.item-banner-quantity,
.item-banner-price {
  font-size: 1rem;
  color: white;
  cursor: pointer;
}

.item-banner-quantity {
  margin-bottom: 0.5rem;
}

.item-banner-actions {
  /* flex: 1; */
  flex-direction: row;
  align-items: center;
}

.product-banner-actions {
  display: flex;
  flex: 1;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-top: 0.75rem;
}

.action-button {
  padding: 0.3rem;
  font-size: 1rem;
  min-width: auto;
  max-width: fit-content;
  /* Remove fixed width */
  display: flex;
  align-items: center;
  justify-content: center;
  /* Center the content horizontally */
}

.action-button > * {
  margin: 0;
  /* Remove default margin on child elements */
}

.action-button:hover {
  background-color: #393e46;
  color: #ffd369;
  cursor: pointer;
  transition:
    border-radius 0.1s,
    background-color 0.1s;
}

.action-button:disabled {
  background-color: #222831;
  color: #ffd369;
}

.action-button_icon {
  margin-right: 0.15rem;
  margin-left: 0.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-banner-crease {
  color: #222831;
  border: 1px solid #ffd369;
  padding: 0.05rem 0.6rem 0.1rem 0.5rem;
  background-color: #ffd369;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  width: auto;
  user-select: none;
  margin-left: 0rem;
  margin-right: 0rem;
}

.item-banner-crease:hover {
  background-color: #393e46;
  color: #1e66ef;
  cursor: pointer;
  transition:
    border-radius 0.1s,
    background-color 0.1s;
}

.count-input {
  text-align: center;
  color: white;
  padding: 0.5rem;
  background-color: #393e46;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  width: 4rem;
  max-width: 7rem;
  height: auto;
  user-select: none;
}

.sound-input {
  text-align: center;
  color: white;
  padding: 0.35rem;
  background-color: #393e46;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  width: 4rem;
  max-width: 7rem;
  height: auto;
  user-select: none;

}

.logo-svg {
  width: 6rem;
  height: auto;
}

.carousel {
  /* width: 80%; */
  margin: 0 auto;
  text-align: center;
}

.carousel__image-container {
  position: relative;
}

.carousel__image {
  width: 75vw;
  max-height: max-content;
  object-fit: cover;
  border-radius: 8px;
}

.carousel__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgb(123, 123, 123);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 50%;
}

.carousel__button--left {
  left: 10px;
}

.carousel__button--right {
  right: 10px;
}

.carousel__thumbnails {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.carousel__thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 8px;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.carousel__thumbnail.active,
.carousel__thumbnail:hover {
  opacity: 1;
  border: 2px solid #333;
}


.login-form {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #393e46;
  border-radius: 5px;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.form-label-title {
  color: #ffd369;
  padding-bottom: 8px;
}

.product-banner-share {
  margin: 0.75rem 0 0.75rem 0;
}

.form-input {
  border-radius: 5px;
  border: 1px solid #ffd369;
  background-color: #393e46;
  color: white;
  width: 15rem;
  margin: 0.7rem;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0.2rem;
  font-size: 1rem;
}

.form-submit {
  color: #ffd369;
  display: flex;
  justify-content: space-between;
  padding: 9px;
  border-radius: 5px;
  background-color: #222831;
  border: 1px solid #ffd369;
  text-decoration: none;
  align-items: center;
}

.form-submit:hover {
  background-color: #393e46;
  transition:
    border-radius 0.1s,
    background-color 0.1s;
}

/* .name-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #222831;
  background-color: #222831;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
} */

.name-title {
  color: #ffd369;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-top: 8px;
  font-size: 1.2rem;
  font-weight: bold;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;
  padding: 10px;
}

.cart-list {
  display: grid;
  grid-template-columns: auto;
  padding: 16px;
  background-color: #222831;
  height: 65vh;
  border-radius: 10px;
}

.cart-item {
  background-color: #393e46;
  margin: 10px;
  padding: 10px;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #ffd369;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  align-items: center;
  cursor: pointer;
}

/* Mobile-friendly styles */

.cart-list_bump {
  padding-bottom: 10px;
  padding-top: 10px;
  overflow: scroll;
  flex: 1;
}

.cart-item__image {
  height: 7vw;
  width: auto;
  border-radius: 10px;
  color: black;
}

.cart-item__actions {
  overflow-wrap: break-word;
  overflow: hidden;
}

.cart_item_actionbuttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 25px;
}

.product-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #222831;
  /* border: 0.3px solid #FFD369 !important; */
}

.product-image {
  display: flex;
  width: 35%;
  height: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.product-actions {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.product-stats {
  display: flex;
  flex: 1;
  font-size: 2rem;
  flex-direction: column;
  color: #ffd369;
}

.product-ability {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0.7rem;
  color: #ffd369;
}

.product-top {
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
}

.product-bottom {
  flex: 1;
  display: grid;
  width: 100%;
  height: 100%;
  overflow: scroll;
  grid-template-columns: 1fr 1fr;
}

.product-info {
  display: flex;
  flex-direction: column;
}

.social-media-icons {
  display: flex;
  width: 65%;
  justify-content: space-between;
  margin-top: 0.5rem;
  width: 50%;
}

.product-rating {
  display: flex;
  flex: 1;
  font-size: 1.2rem;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  padding: 0.5rem;
  border-radius: 5px;
}

.product-rating-stars {
  padding: 0 1rem 0 1rem;
  cursor: pointer;
}

.view-reviews {
  margin-left: 0.5rem;
  cursor: pointer;
}

/* on hover */

.product-name {
  display: block;
  overflow-y: scroll;
  font-size: 2rem;
  color: #ffd369;
  margin: 0.3rem 0 0.3rem 0;
}

.product-stock,
.product-price {
  display: flex;
  overflow-y: scroll;
  color: #ffd369;
  margin-bottom: 0.3rem;
}

.product-price-banner {
  display: flex;
  overflow-y: scroll;
  color: #ffd369;
  margin-bottom: 0.3rem;
}

.order-header {
  height: 3rem;
  max-height: 3rem;
}

.product-media {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.2rem;
  align-items: center;
}

.product-reviews-container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  color: #ffd369;
}

.product-review {
  border: 1px solid #ffd369;
  margin: 0.5rem 0rem 0.5rem 0rem;
  border-radius: 5px;
}

.review-header {
  display: flex;
  width: auto;
  font-size: 0.8rem;
  padding: 0.5rem;
  flex-direction: row;
  color: #ffd369;
  align-items: center;
}

.review-text {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0.5rem;
  color: #ffd369;
}

.product-review-form {
  display: flex;
  flex-direction: column;
  color: #ffd369;
}

.my-custom-flag-class {
  font-size: 1.35em; /* Increase the flag size */
  width: 1.35em;     /* Adjust width according to size */
  height: auto;   /* Maintain aspect ratio */
}


.subsection-title {
  font-size: 1.5rem;
  color: #ffd369;
  padding: 0;
  margin-left: 0;
}

.section-subtitle {
  margin: 0.25rem 0 0.25rem 0;
}

.product-rating-xo1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
}

.product-rating-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  padding: 0.5rem;
  color: #ffd369;
  padding: 0.5rem 0rem 0rem 0rem;
}

.review-textarea {
  width: 100%;
  height: 12vh;
  border-radius: 5px;
  padding: 8px;
  border: 1px solid #ffd369;
  background-color: #393e46;
  color: white;
  margin-bottom: 10px;
  /* fixed size */
  resize: none;
  font-size: 1rem;
}

.carousel {
  display: flex;
  flex-direction: column;
}

.review {
  border-radius: 10px;
  text-align: left;
  transition:
    transform 1s ease-in-out,
    opacity 1s ease-in-out;
}

.slide-in {
  opacity: 1;
  transform: translateX(0);
}

.slide-out {
  opacity: 0;
  transform: translateX(-100%);
}

.input-label {
  color: #ffd369;
  padding-top: 0.35rem;
  font-size: 1rem;
}

.image-product {
  width: auto;
  max-height: 30rem;
}

.checkout-item-name {
  display: flex;
  flex-direction: column;
  width: 7rem;
  padding: 0.5rem 0.5rem 0.5rem 0rem;
  color: #ffd369;
  font-size: 1.5rem;
  margin-bottom: 0 !important;
}

.home-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.descriptions-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 20px;
  font-size: 1.5rem;
  background-color: #393e46;
  border-radius: 5px;
  width: 75%;
}

.title-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.desc_2,
.desc_1 {
  margin: 1rem !important;
  color: #ffd369;
}

.item-price-singular,
.item-price {
  display: flex;
  width: 7rem;
  padding: 0.5rem 0.5rem 0.5rem 0rem;
  color: #ffd369;
  margin-bottom: 0 !important;
}


.option {
  margin-top: 0px !important;
}

.item-price-singular {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column !important;
}

.product-text {
  display: flex;
  flex: 1;
  padding: 0.5rem;
  color: #ffd369;
}

.product-banner-description {
  color: #ffd369;
  border-radius: 2px;
  margin-top: 1.2rem;
  display: flex;
  flex-direction: column;
  max-height: 15rem;
  font-size: 1.5rem;
  /* Set a maximum height for the container */
  overflow-y: auto;
  /* Add this line to enable vertical scroll when content overflows */
}

.product-banner-misc-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 20rem;
  /* Set a maximum height for the container */
  overflow-y: auto;
  /* Add this line to enable vertical scroll when content overflows */
}

.navbar-toggle-bar__extended {
  display: none;
}

.product-description {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.video-frame {
  display: flex;
  flex: 1;
  justify-content: center;
}

.video-iframe {
  display: flex;
  width: 80%;
  margin: 2rem 0 2rem 2rem;
}

.center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 2rem;
  color: #ffd369;
}

.cart-icon-container {
  position: relative;
  display: flex;
  align-items: center;
}

.cart-link {
  position: relative;
  text-decoration: none;
  color: inherit;
}

.cart-count {
  position: absolute;
  top: -12px;
  right: -19px;
  background-color: #1e66ef;
  color: white;
  border-radius: 50%;
  border-color: #1e66ef !important;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* form */

.input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  /* padding-bottom: 0.5rem; */
}

.cart-table {
  color: #ffd369;
  border-collapse: collapse;
  width: fit-content;
  margin: 0 0 1rem 1.5rem;
  /* Adding border radius */
}

.cart-table td {
  border: 0.5px solid #ffd369;
  background-color: #222831;
  padding: 8px;
}

label {
  color: #ffd369;
  padding-bottom: 5px;
}

.checkout-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.checkout-item {
  display: flex;
  /* fixed height */
  height: 5rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border: 1px solid #ffd369;
  border-radius: 5px;
  margin: 0.7rem;
  background-color: #222831;
}

.overlay-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999; /* Ensure it's below the expanding content */
  display: none; /* Hidden by default */
  cursor: pointer;
}

.overlay-background.visibel {
  display: block;
}

.overlay-content {
  position: fixed;
  top: 2.5%;
  left: 5%;
  height: 95%;
  background: white;
  z-index: 1000; /* Above the background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  width: 0;
  border-radius: 10px !important;
  transition: width 0.3s ease-in-out
}

.cart-overlay-content {
  position: fixed;
  top: 2.5%;
  right: 5%;
  height: 95%;
  background: white;
  z-index: 1000; /* Above the background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  width: 0;
  border-radius: 10px !important;
}


.overlay-content.expanded,
.cart-overlay-content.expanded {
  opacity: 1;
  transform: translateY(0);
}


.language-dropdwn {
  position: fixed;
  bottom: 5%;
  left: 25%;
  width: 50%; /* Set a fixed width */
  background: white;
  z-index: 2001; /* Above the background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: height 0.3s ease;
  height: 0;
  border-radius: 10px !important;
}


.overlay-content.expanded {
  width: 90%;
  border-radius: 10px;
}

.cart-overlay-content.expanded {
  width: 90%;
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .language-dropdwn.expanded{
    left: 12.5% !important;
    width: 75% !important;
    transition: height 0.3s ease;
    height: 0;
  
  }

  .language-dropdwn {
    left: 12.5% !important;
    width: 75% !important;
    transition: height 0.3s ease;
    height: 0;
  }
}

@media screen and (min-width: 750px) {
  .language-dropdwn.expanded{
    left: 35%;
    width: 30% !important;
  }

  .language-dropdwn {
    left: 35% !important;
    width: 30% !important;
  }
}


.language-dropdwn.expanded {
  height: 20%; /* Set the height when expanded */
  border-radius: 10px;
}

.overlay-inner-content {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.overlay-close {
  top: 2.5%;
  left: 5%;
  cursor: pointer;
  border-radius: 10px !important;
}

.item-details {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: #ffd369;
}

p {
  margin: 0 !important;
}

.item-quantity {
  display: flex;
  margin: 0;
}

.checkout-items {
  display: grid;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
}

.checkout-img {
  display: flex;
  width: 3rem;
  height: 3rem;
}

.item-banner-img {
  width: 15rem;
  height: 15rem;
  max-width: 15rem;
  padding: 0.7rem 0 0.7rem 0;
}

.form-container {
  display: flex;
  background-color: #393e46;
  border-radius: 5px;
  height: fit-content;
}

input {
  /* width: 65%; */
  height: 3vh;
  padding-left: 4px;
}

.dropdown-selector {
  color: white;
  border: 1px solid #ffd369;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: 0.5rem;
  height: 2.75rem;
  /* Disable text selection */
  margin-bottom: 10px;
}

.dropdown {
  position: relative;
  color: #333;
  cursor: default;
}

.dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.bottomtask{
  bottom: 6.5%;
}

.bottomtasks{
  bottom: 15%;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.navbar-parent {
  display: flex;
  /* This makes #main a flex container */
  height: 100%;
  width: auto;
  background: #0a3d79;
}

.menu-separator {
  display: flex;
  flex: 1;
  border-bottom: 0.2px solid #6a7079;
  width: 75%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.dropdown .selected-value input {
  line-height: 1.5;
  height: 2.75rem;
  font-size: 1rem;
  cursor: default;
  outline: none;
  transition: all 200ms ease;
  /* width: 65%; */
}

.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.navbar-toggle-bar {
  display: none;
}

.dropdown .options.open {
  display: block;
}

.dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  margin-top: 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  /* background-color: #f2f9fc;
  color: #333; */
}

.order-summary-container{
  overflow-y: auto;
}

.map-container {
  display: flex;
  height: 30rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #393e46;
  border-radius: 5px;
}

.regular-input {
  margin: 0.7rem;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0.2rem;
  /* width: 50%; */
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  height: 2.75rem;
}

.toast-success,
.toast-error {
  background: #f0f0f0;
  color: #272727 !important;
  border-radius: 1rem !important;
  border: 1px solid #272727 !important;
  border-color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  padding: 30px 20px;
}

.link_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 1rem;
  border-radius: 5px;
  background-color: #393e46;
  color: #ffd369;
  font-size: 1.3rem;
  width: 40%;
}

@media (min-width: 1600px) {
  .item-list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* Display a single column on small screens */
  }
}

@media (max-width: 1350px) {
  .item-list {
    grid-template-columns: 1fr 1fr 1fr;
    /* Display a single column on small screens */
  }

  .cart-list {
    height: auto;
    /* Adjust height to auto for smaller screens */
    grid-template-columns: 1fr;
    /* Display a single column on small screens */
    border-radius: 0;
    /* Remove border-radius on small screens if needed */
  }
}

@media (max-width: 1100px) {
  .product-container {
    flex-direction: column;
    font-size: 2rem;
  }

  .product-image {
    scale: 10px;
    width: 100%;
    height: 10rem;
  }

  .image-product {
    width: auto;
    height: 25rem;
  }
}

@media (max-width: 1000px) {
  html {
    font-size: 0.90rem;
  }

  .product-media {
    flex-direction: column;
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .descriptions-container {
    width: 90%;
    padding: 1rem;
    font-size: medium;
    text-align: center;
  }

  .order-container {
    grid-template-columns: 1fr 1fr;
  }

  .item-list {
    grid-template-columns: 1fr 1fr 1fr;
    /* Display a single column on small screens */
  }

  .cart-list {
    height: auto;
    /* Adjust height to auto for smaller screens */
    grid-template-columns: 1fr;
    /* Display a single column on small screens */
    border-radius: 0;
    /* Remove border-radius on small screens if needed */
  }
}

@media (max-width: 675px) {
  .item-list {
    grid-template-columns: 1fr 1fr;
    /* Display a single column on small screens */
  }

  .cart-list {
    height: auto;
    /* Adjust height to auto for smaller screens */
    grid-template-columns: 1fr;
    /* Display a single column on small screens */
    border-radius: 0;
    /* Remove border-radius on small screens if needed */
  }
}

@media (max-width: 600px) {
  html {
    font-size: 0.85rem;
  }

  .link {
    padding: 0.5rem;
  }

  .review-textarea {
    width: auto;
  }

  .form-input {
    width: auto;
  }

  .dropbtn {
    padding: 0.75rem;
  }

  .map-container {
    /* flex: none; */
    height: 20rem;
    width: 100%;
    margin-bottom: 20px;
  }

  .header {
    flex: none;
    height: 6vh !important;
    min-height: 8.5vh !important;
  }

  .link {
    justify-content: center;
  }

  .link-title {
    display: none;
  }

  .navbar-container_extended {
    position: fixed;
    /* Add this line */
    width: 7rem;
    min-width: 7rem;
    overflow-x: hidden;
    z-index: 9999;
  }

  .checkout-items {
    margin: 0;
    padding: 0.5rem;
  }

  .checkout-item {
    margin: 0.5rem;
    flex-direction: column;
    height: auto;
  }

  .item-details {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    flex: 1;
    width: 90%;
  }

  .product-banner-actions {
    width: 100%;
  }

  .product-rating {
    flex: none;
    font-size: 1.5rem;
  }

  .checkout-img {
    width: 8rem;
    height: 8rem;
    padding: 0.5rem;
  }

  .app-contents {
    /* padding: 0.4rem; */
  }

  .item-list {
    grid-template-columns: 1fr;
    /* padding: 0.7rem; */
    gap: 0.7rem;
    /* Display a single column on small screens */
  }

  .cart-list {
    height: auto;
    /* Adjust height to auto for smaller screens */
    grid-template-columns: 1fr;
    /* Display a single column on small screens */
    border-radius: 0;
    /* Remove border-radius on small screens if needed */
  }
}

/* navbar mobile */
@media (max-width: 500px) {
  /* .navbar-container {
    width: 0px;
    min-width: 0px;
    margin: 0px;
    padding: 0px;
    display: none;
  } */

  .navbar-parent {
    transition: 1s;
  }

  .navbar-parent__extended {
    display: flex;
    position: absolute;
    top: 0;
    /* This makes #main a flex container */
    height: 100%;
    width: auto;
    background: #0a3d79;
    z-index: 99999;
    border-right: 2px solid #ffd369;
  }

  /* .navbar-container_extended {
    display: flex !important;
    position: fixed;
    width: 7rem;
    min-width: 7rem;
    overflow-x: hidden;
    z-index: 9999;
  } */

  .navbar-container_extended {
    position: fixed;
    /* Add this line */
    width: 7rem;
    min-width: 7rem;
    overflow-x: hidden;
    z-index: 9999;
  }

  .desc_1 {
    display: none;
  }

  .navbar-toggle-bar {
    display: flex !important;
    position: relative;
    height: 100vh;
    flex-direction: row;
    align-items: baseline;
    justify-content: start;
    background-color: #222831;
  }

  .navbar-toggle-bar__extended {
    display: flex !important;
    position: relative;
    height: 100vh;
    flex-direction: row;
    align-items: baseline;
    justify-content: start;
    background-color: #222831;
    border-radius: 5px;
  }

  .navbar-toggle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    margin: 0.7rem;
    width: auto;
    height: auto;
    margin-top: 2rem;
    background-color: #ffd369;
    border-radius: 5px;
    cursor: pointer;
  }
}
