@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.big-text {
  text-transform: uppercase !important;
  font-weight: 550;
  letter-spacing: 2px;
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.big-text::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px; /* Adjust based on your text position */
  width: 0;
  height: 2px; /* Adjust based on your desired underline thickness */
  background-color: #FFD369; /* Adjust based on your desired underline color */
  transition: width 0.3s ease-in-out;
}

.big-text-div:hover .big-text::after {
  width: 100%;
}

.big-text-div {
  display: inline-block;
  position: relative;
  text-decoration: none;
  cursor: pointer;
}

.big-text-div:hover {
  transition: background-color 0.1s ease-in-out;
  /* background-color: #2f3339; */
  text-decoration: none;
}
